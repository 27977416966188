import React from "react";
import { Box, Divider, Typography, styled } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { lockIcon, privacyIcon, termsIcon } from "../assets";
import { NavigationButton } from "./CommonComponents";

interface SettingSideNavigationProps {
  navigateTo: (path: string) => void;
  onClose?: () => void;
}
const SettingSideNavigation: React.FC<SettingSideNavigationProps> = ({
  navigateTo,
  onClose,
}) => {
  const handleNavigate = (path: string) => {
    navigateTo(path);
    if (onClose) {
      onClose();
    }
  };
  const accountNav = [
    {
      icon: lockIcon,
      label: "Change Password",
      to: "ChangePassword",
    },
  ];
  const supportNav = [
    {
      icon: termsIcon,
      label: "Terms and conditions",
      to: "TermsConditions",
    },
    {
      icon: privacyIcon,
      label: "Privacy policy",
      to: "PrivacyPolicy",
    },
  ];
  return (
    <StyledSettingNavigationContainer>
      <Box className="navigationHeading">
        <Typography sx={webStyle.navigationTabHeading}>Settings</Typography>
        <NavigateBeforeIcon className="closeDrawer" onClick={onClose} />
      </Box>
      <Box
        className="profileButton"
        data-testid="profileNavigation"
        onClick={() => {
          handleNavigate("Profile");
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Box sx={webStyle.profileNameLogoContainer}>
            <Typography sx={webStyle.profileNameLogo}> JD </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Typography sx={webStyle.userName}>John Doe</Typography>
            <Typography sx={webStyle.editProfileLabel}>Edit profile</Typography>
          </Box>
        </Box>
        <NavigateNextIcon sx={{ color: "#0074BF" }} />
      </Box>
      <Box className="sectionContainer">
        <Box sx={webStyle.sections}>
          <Typography sx={webStyle.sectionLabel}>ACCOUNT</Typography>
          <Divider />
          <Box
            data-testid="accountNavigation"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {accountNav.map((navigation, index) => (
              <NavigationButton
                key={index}
                onClick={() => {
                  handleNavigate(navigation.to);
                }}
                label={navigation.label}
                icon={navigation.icon}
              />
            ))}
          </Box>
        </Box>

        <Box sx={webStyle.sections}>
          <Typography sx={webStyle.sectionLabel}>SUPPORT</Typography>
          <Divider />
          <Box
            data-testid="supportNavigation"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            {supportNav.map((navigation, index) => (
              <NavigationButton
                key={index}
                onClick={() => {
                  handleNavigate(navigation.to);
                }}
                label={navigation.label}
                icon={navigation.icon}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </StyledSettingNavigationContainer>
  );
};

export default SettingSideNavigation;

const StyledSettingNavigationContainer = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "column",
  background: "#ffffff",
  borderBottomRightRadius: "64px",
  borderRight: "1px solid #E2E8F0",
  borderBottom: "1px solid #E2E8F0",
  gap: "10px",
  overflowY: "auto",
  "& .navigationHeading": {
    display: "flex",
    height: "80px",
    paddingLeft: "30px",
    alignItems: "center",
    position: "relative",
    "& .closeDrawer": {
      display: "none",
      position: "absolute",
      color: "#0074BF",
      left: "30px",
      top: "29px",
    },
  },
  "& .profileButton": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "20px",
    padding: "10px 10px 10px 10px",
    marginLeft: "20px",
    marginRight: "20px",
  },
  "& .sectionContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  "@media (max-width: 900px)": {
    borderBottomRightRadius: "0px",
    borderRight: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    "& .navigationHeading": {
      justifyContent: "center",
      "& .closeDrawer": {
        display: "block",
      },
    },
  },
});
const webStyle = {
  navigationTabHeading: {
    fontFamily: "Montserrat",
    color: "#0074BF",
    fontSize: "20px",
    fontWeight: 700,
  },
  profileNameLogoContainer: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    border: "2px solid #0074BF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffff",
  },
  profileNameLogo: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#0074BF",
    fontFamily: "Montserrat",
  },
  sections: {
    display: "flex",
    flexDirection: "column",
  },
  sectionLabel: {
    fontWeight: 700,
    fontSiz: "14px",
    fontFamily: "Montserrat",
    color: "#94A3B8",
    padding: "0px 5px 0px 35px",
  },

  editProfileLabel: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#334155",
  },
  userName: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    color: "#0F172A",
  },
};
