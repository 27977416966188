import React from "react";

// Customizable Area Start
import { AppBar, Box, Typography, styled } from "@mui/material";
import {
  appLogo,
  calender,
  settings,
  notifications,
  mail,
  listIcon,
} from "./assets";

import SettingSideNavigation from "./components/SettingSideNavigation";
import MobileSideNavigation from "./components/MobileSideNavigation";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleOpenDrawer() {
    this.setState({ isOpenDrawer: true });
  }

  handleCloseDrawer() {
    this.setState({ isOpenDrawer: false });
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Container>
          <Box className="verticalSideBar">
            <Box className="OuterNavbar">
              <Box>
                <img src={appLogo} />
              </Box>
              <Box className="midNavContainer">
                <Box className="navButtonContainer">
                  <img src={calender} />
                  <Typography className="navButtonLabel">Calendar</Typography>
                </Box>

                <Box className="navButtonContainer">
                  <img src={listIcon} />
                  <Typography className="navButtonLabel">Checklist</Typography>
                </Box>

                <Box className="navButtonContainer">
                  <img src={notifications} />
                  <Typography className="navButtonLabel">
                    Notifications
                  </Typography>
                </Box>

                <Box className="navButtonContainer">
                  <img src={mail} />
                  <Typography className="navButtonLabel">Messages</Typography>
                </Box>

                <Box className="navButtonContainer">
                  <img src={settings} />
                  <Typography className="navButtonLabel">Settings</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "fit-content",
                  width: "40px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                data-testid="profileLogo"
                onClick={() => {
                  this.props.navigation.navigate("Profile");
                }}
              >
                <Typography
                  sx={{
                    color: "#0074BF",
                    backgroundColor: "#E0F2F8",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    fontFamily: "Montserrat, sans-serif",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  JD
                </Typography>
                <Typography sx={webStyle.iconLabel}>Profile</Typography>
              </Box>
            </Box>
            <SettingSideNavigation
              navigateTo={this.props.navigation.navigate}
            />
          </Box>
          <Box className="childContainer">{this.props.children}</Box>
          <AppBar sx={{position:"fixed", bottom:0, top:"auto", zIndex:"1800"}}>
            <Box className="horizontalNavBar">
              <Box className="navButtonContainer">
                <img src={calender} />
                <Typography className="navButtonLabel">Calendar</Typography>
              </Box>
              <Box className="navButtonContainer">
                <img src={notifications} />
                <Typography className="navButtonLabel">
                  Notifications
                </Typography>
              </Box>
              <Box className="navButtonContainer">
                <img src={mail} />
                <Typography className="navButtonLabel">Messages</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "fit-content",
                  width: "40px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                data-testid="mobileProfileLogo"
                onClick={this.handleOpenDrawer.bind(this)}
              >
                <Typography
                  sx={{
                    color: "#0074BF",
                    backgroundColor: "#E0F2F8",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    fontFamily: "Montserrat, sans-serif",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  JD
                </Typography>
                <Typography sx={webStyle.iconLabel}>Profile</Typography>
              </Box>
              <MobileSideNavigation
                data-testid="mobileNavigationMenu"
                open={this.state.isOpenDrawer}
                navigateTo={this.props.navigation.navigate}
                handleClose={this.handleCloseDrawer.bind(this)}
              />
            </Box>
          </AppBar>
        </Container>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Container = styled(Box)({
  display: "flex",
  width: "100vw",
  height: "100vh",
  background: "#f1f5f9",
  "& .verticalSideBar": {
    display: "flex",
    width: "400px",
    positions: "fixed",
    "& .OuterNavbar": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "column",
      width: "80px",
      background: "#FFFFFF",
      padding: "20px",
      borderRight: "1px solid #E2E8F0",
      "& .midNavContainer": {
        display: "flex",
        flexDirection: "inherit",
        gap: "40px",
      },
    },
  },
  "& .horizontalNavBar": {
    display: "none",
    height: "60px",
    justifyContent: "space-between",
    alignItems: "center",
    background: "white",
    borderTop: "1px solid #E2E8F0",
    padding: "10px",
    zIndex: 20,
  },
  "& .childContainer": {
    flexGrow: 1,
    overflowY: "auto",
  },

  "& .navButtonContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .navButtonLabel": {
      fontSize: "12px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      color: "#94A3B8",
    },
  },
  "@media (max-width: 900px)": {
    flexDirection: "column",
    "& .verticalSideBar": {
      display: "none",
      "& .OuterNavbar": {
        flexDirection: "row",
        width: "100%",
        height: "70px",
      },
    },
    "& .horizontalNavBar": {
      display: "flex",
    },
    "& .childContainer": {
      paddingBottom:'90px'
    },
  },
});

const webStyle = {
  outerContainer: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    background: "#f1f5f9",
  },
  iconLabel: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14.63px",
    letterSpacing: "-0.24px",
    textAlign: "center",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color:"#0074BF"
  },
};

// Customizable Area End
