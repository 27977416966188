import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { toast } from "react-toastify";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  name: string;
  dms: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  consent: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  privacyPolicyOpen: boolean;
  termsOpen: boolean;
  errors: {
    [key: string]: string;
  };
  passwordStrength: number;
  isValid: boolean;
  hasViewedPrivacyPolicy: boolean;
  hasViewedTerms: boolean;
}

export interface SS {
  id: any;
}

export interface PasswordRequirement {
  text: string;
  regex?: RegExp;
  test?: (value: string) => boolean;
}

export default class SignupController extends BlockComponent<Props, S, SS> {
  validationApiCallId: string = "";
  createAccountApiCallId: string = "";
  passwordRequirements: PasswordRequirement[];
  role: string | null;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      name: "",
      dms: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      consent: false,
      showPassword: false,
      showConfirmPassword: false,
      privacyPolicyOpen: false,
      termsOpen: false,
      errors: {},
      passwordStrength: 0,
      isValid: false,
      hasViewedPrivacyPolicy: false,
      hasViewedTerms: false
    };
    
    this.role = new URLSearchParams(window.location.search).get('role')

    this.passwordRequirements = [
      { text: "At least one capital letter", regex: /[A-Z]/ },
      { text: "At least one lowercase letter", regex: /[a-z]/ },
      { text: "At least one number", regex: /\d/ },
      { text: "At least one special character", regex: /[!@#$%^&*(),.?":{}|<>]/ },
      { text: "Minimum character length is 8 characters", test: (value: string) => value.length >= 8 }
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } else if (responseJson?.errors) {
          toast.error(responseJson?.errors[0]?.account);
        } else
          if (apiRequestCallId === this.createAccountApiCallId) {
            localStorage.setItem("accessToken", responseJson.meta.token);
            if (this.role === 'applicant') {
              this.props.navigation.navigate('Profile')
            } else {
              this.props.navigation.navigate('AccountStatus')
            }
          }

      }
    }
  }  

  goBack = () => {
    this.props.navigation.navigate('Startup',{selected:'from-signup'})
  }

  togglePasswordVisibility = (field: 'showPassword' | 'showConfirmPassword') => {

    this.setState((prevState) => ({
      ...prevState,
      [field]: !prevState[field]
    }));
  }

  handleInputChange = (field: keyof S, value: string | boolean) => {
    this.setState((prevState) => {
      const newState = { ...prevState, [field]: value };
      
      if (field === 'phone') {
        // Allow cursor positioning by preserving structure
        const cursorPosition = (document.querySelector('input[name="phone"]') as HTMLInputElement)?.selectionStart || 0;
        const oldValue = prevState.phone;
        const cleaned = value.toString().replace(/\D/g, '');
        let formatted = this.formatPhoneNumber(cleaned);
        
        // Adjust cursor position based on added/removed hyphens
        const hyphensBefore = (oldValue.slice(0, cursorPosition).match(/-/g) || []).length;
        const hyphensAfter = (formatted.slice(0, cursorPosition).match(/-/g) || []).length;
        const cursorAdjustment = hyphensAfter - hyphensBefore;
        
        newState.phone = formatted;
        
        // Set cursor position after state update
        setTimeout(() => {
          const input = document.querySelector('input[name="phone"]') as HTMLInputElement;
          if (input) {
            input.selectionStart = input.selectionEnd = cursorPosition + cursorAdjustment;
          }
        }, 0);
      }
      
      if (field === 'password') {
        this.checkPasswordStrength(value as string);
        if (newState.confirmPassword) {
          this.validateField('confirmPassword', newState.confirmPassword);
        }
      }

      this.validateField(field, value);
      this.checkFormValidity(newState);
      return newState;
    });
  }

  formatPhoneNumber = (value: string) => {
    const cleaned = value.slice(0, 10); // Limit to 10 digits
    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    } else {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
    }
  }

  validateField = (field: keyof S, value: string | boolean) => {
    this.setState(prevState => {
      const errors = { ...prevState.errors };

      switch (field) {
        case 'name':
          if (!value) {
            errors.name = 'Name is required';
          } else if (!/^[A-Za-z\s]+$/.test(value as string)) {
            errors.name = 'Name should contain alphabetic characters';
          } else {
            errors.name = '';
          }
          break;

        case 'phone':
          errors.phone = /^\d{3}-\d{3}-\d{4}$/.test(value as string)
            ? ''
            : 'Please enter a valid phone number';
          break;

        case 'email':
          errors.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value as string)
            ? ''
            : 'Invalid email address';
          break;

        case 'password':
          const meetsRequirements = this.passwordRequirements.every(req => 
            req.regex ? req.regex.test(value as string) : req.test ? req.test(value as string) : true
          );
          errors.password = meetsRequirements ? '' : 'Password does not meet requirements';
          if (prevState.confirmPassword) {
            errors.confirmPassword = prevState.confirmPassword === value ? '' : 'Passwords do not match';
          }
          break;

        case 'confirmPassword':
          errors.confirmPassword = value === prevState.password ? '' : 'Passwords do not match';
          break;

        case 'consent':
          errors.consent = value ? '' : 'You must accept the terms and conditions';
          break;
      }

      return { errors };
    });
  }

  checkFormValidity = (currentState: S) => {
    const isValid = 
      currentState.name.trim() !== '' &&
      currentState.email.trim() !== '' &&
      currentState.phone.match(/^\d{3}-\d{3}-\d{4}$/) !== null &&
      currentState.password.length >= 8 &&
      currentState.password === currentState.confirmPassword &&
      currentState.consent &&
      currentState.hasViewedPrivacyPolicy &&
      currentState.hasViewedTerms &&
      Object.values(currentState.errors).every(error => error === '') &&
      this.passwordRequirements.every(req => 
        req.regex ? req.regex.test(currentState.password) : req.test ? req.test(currentState.password) : true
      );

    this.setState({ isValid });
  }

  setPrivacyPolicyOpen = (open: boolean) => {
    this.setState(prevState => {
      const newState = { 
        privacyPolicyOpen: open,
        hasViewedPrivacyPolicy: prevState.hasViewedPrivacyPolicy || open 
      };
      this.checkFormValidity({ ...prevState, ...newState });
      return newState;
    });
  }

  setTermsOpen = (open: boolean) => {
    this.setState(prevState => {
      const newState = { 
        termsOpen: open,
        hasViewedTerms: prevState.hasViewedTerms || open 
      };
      this.checkFormValidity({ ...prevState, ...newState });
      return newState;
    });
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.isValid) {
      this.createAccount();
    }
  }

  getPasswordRequirementIconStyle = (requirement: PasswordRequirement, password: string) => {
    const webStyle = {
      checkIcon: {
        color: '#34D399',
        fontSize: '16px',
        marginRight: '8px',
      },
      uncheckIcon: {
        color: '#9E9E9E',
        fontSize: '16px',
        marginRight: '8px',
      },
    };
    const isMet = requirement.regex
      ? requirement.regex.test(password)
      : requirement.test
        ? requirement.test(password)
        : true;
    return isMet ? webStyle.checkIcon : webStyle.uncheckIcon;
  }

  validateForm = () => {
    let isValid = true;
    let errors = { ...this.state.errors };

    (Object.keys(this.state) as Array<keyof S>).forEach((key) => {
      if (key !== 'errors' && key !== 'showPassword' && key !== 'showConfirmPassword' &&
        key !== 'privacyPolicyOpen' && key !== 'termsOpen' && key !== 'passwordStrength') {
        const value = this.state[key];
        if (typeof value === 'string' || typeof value === 'boolean') {
          this.validateField(key, value);
          if (errors[key]) isValid = false;
        }
      }
    });

    this.setState({ errors });
    return isValid;
  }

  checkPasswordStrength = (password: string) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/\d+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;

    this.setState({ passwordStrength: strength });
  }

  createAccount = () => {
    const headers = {
      "Content-Type": "application/json",
    };

    const [firstName, ...lastNameParts] = this.state.name.split(' ');
    const lastName = lastNameParts.join(' ');
    const { name, dms, phone, email, password } = this.state;
    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          first_name: firstName,
          last_name: lastName,
          user_name: name,
          email: email,
          password: password,
          full_phone_number: `+1${phone.replace(/[^+\d-]/g, '')}`,
          date_of_birth: "2000-06-29",
          country_code: 1,
          phone_number: phone.replace(/\D/g, ''),
          ...(this.role === 'applicant' && { activated: true }),
          [this.role === 'applicant' ? 'ftn' : 'dms']: dms,
          role_id: this.role === 'applicant' ? 1 : 2
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

}
// Customizable Area End