import React from "react";
import {
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  Grid,
  Checkbox,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogContentText
} from "@mui/material";
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import SignupController, { Props } from "./SignupController.web";
import { loginLogo, loginImage } from "./assets";

// Customizable Area Start
import { leftArrow } from "./assets";
import PrivacyPolicyModal from "./components/PrivacyPolicyModal";
import TermsAndConditionsModal from "./components/TermsAndConditionsModal";

export default class Signup extends SignupController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { handleInputChange, togglePasswordVisibility, handleSubmit, setPrivacyPolicyOpen, setTermsOpen } = this;
    const { name, dms, phone, email, password, confirmPassword, consent, showPassword, showConfirmPassword, privacyPolicyOpen, termsOpen, errors } = this.state;

    return (
      <>
        <div className="Toastify"></div>
        <Grid container direction={'row'}>
          <Grid item xs={6} sx={{
            height: "100%",
            overflowY: "scroll"
          }}>
            <img src={loginLogo} style={{ width: "150px", height: "61px", padding: "15px" }} alt="Login Logo" />
            <form onSubmit={handleSubmit} data-testid="signup-form">
              <Box sx={webStyle.formWrapper}>
                <Box style={{ maxWidth: "400px", display: 'flex', marginBottom: '20px' }}>
                  <span onClick={()=>this.goBack()} style={{ position: "relative", right: "250px" }}>
                    <img src={leftArrow} style={{ margin: '7px 0px', height: '19px', width: '19px' }} alt="left-arrow" />
                  </span>
                  <Typography variant="h6" sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 700, fontSize: "22px", color: "#001C55",
                    position: "absolute", left: '255px'
                    // display: 'flex', justifyContent: "space-between", alignItems: 'center'
                  }}>

                    Start Scheduling Now!
                  </Typography>
                </Box>
              </Box>
              <Box sx={webStyle.formWrapper}>


                {/* </Box> */}
                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Name</InputLabel>
                  <TextField
                    name="name"
                    value={name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter your full name"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    data-testId="name"
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>
                    {this.role == 'dpe' ? 'DMS Number' : 'FTN'}
                  </InputLabel>
                  <TextField
                    name="dms"
                    value={dms}
                    data-testId="dms"
                    onChange={(e) => handleInputChange("dms", e.target.value)}
                    fullWidth
                    variant="outlined"
                    placeholder={this.role === "dpe" ? "Enter your DMS Number" : "Enter your Flight Training Number"}
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    // error={!!errors.dms}
                    helperText={errors.dms}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Phone Number</InputLabel>
                  <TextField
                    name="phone"
                    value={phone}
                    onChange={(e) => handleInputChange("phone", e.target.value)}
                    fullWidth
                    variant="outlined"
                    type="tel"
                    data-testId="phone"
                    placeholder="555-123-4567"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Email</InputLabel>
                  <TextField
                    name="email"
                    value={email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    fullWidth
                    data-testId="email"
                    variant="outlined"
                    type="email"
                    placeholder="john.doe@example.com"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Password</InputLabel>
                  <TextField
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => handleInputChange("password", e.target.value)}
                    fullWidth
                    variant="outlined"
                    data-testId="password"
                    placeholder="Enter a secure password"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle,
                      endAdornment: (
                        <IconButton
                          onClick={() => togglePasswordVisibility('showPassword')}
                          edge="end"
                          data-testId="pass-icon"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                  {password && <Box sx={webStyle.passwordRequirements}>
                    {this.passwordRequirements.map((req, index) => (
                      <Box key={index} sx={webStyle.requirementItem}>
                        <CheckCircleIcon
                          sx={this.getPasswordRequirementIconStyle(req, password)}
                        />
                        <Typography sx={webStyle.requirementText}>
                          {req.text}
                        </Typography>
                      </Box>
                    ))}
                  </Box>}
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <InputLabel style={webStyle.label}>Confirm Password</InputLabel>
                  <TextField
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
                    fullWidth
                    variant="outlined"
                    data-testId="confirmPass"
                    placeholder="Confirm your password"
                    sx={webStyle.textFieldStyle}
                    InputProps={{
                      style: webStyle.inputStyle,
                      endAdornment: (
                        <IconButton
                          onClick={() => togglePasswordVisibility('showConfirmPassword')}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                </Box>

                <Box sx={webStyle.inputWrapper}>
                  <Box sx={webStyle.consentContainer}>
                    <Checkbox
                      name="consent"
                      checked={consent}
                      data-testId="consent"
                      onChange={(e) => handleInputChange("consent", e.target.checked)}
                      sx={webStyle.checkbox}
                    />
                    <Typography sx={webStyle.consentText}>
                      I hereby consent to the
                      <Button
                        onClick={() => setPrivacyPolicyOpen(true)}
                        sx={{
                          ...webStyle.consentButton,
                          color: '#0F172A',
                        }}
                      >
                        Privacy Policy
                      </Button>
                      and
                      <Button
                        onClick={() => setTermsOpen(true)}
                        sx={{
                          ...webStyle.consentButton,
                          color: '#0F172A',
                        }}
                      >
                        Terms and Conditions
                      </Button>
                    </Typography>
                  </Box>
                  {errors.consent && (
                    <Typography color="error" variant="caption">
                      {errors.consent}
                    </Typography>
                  )}
                </Box>

                <Button
                  type="submit"
                  sx={{
                    ...webStyle.buttonStyle,
                    backgroundColor: !this.state.isValid ? '#CBD5E1' : '#0074BF',
                    cursor: !this.state.isValid ? 'not-allowed' : 'pointer',
                  }}
                  variant="contained"
                  disabled={!this.state.isValid}
                >
                  Create account
                </Button>

                <Typography variant="body2" align="center"
                  sx={{
                    fontWeight: 400, fontSize: "16px"
                  }}
                >
                  Already have an account?
                  <Link to="/Login" style={{
                    fontFamily: 'Montserrat, sans-serif', display: "inline",
                    fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                    marginLeft: "7px"
                  }}>
                    Login
                  </Link >
                </Typography>
              </Box>
            </form>
          </Grid>
          <Grid item xs={6} padding={0}>
            <img src={loginImage} style={{ height: "100vh", width: "100%" }} alt="Login" />
          </Grid>
        </Grid>
        <PrivacyPolicyModal
          open={privacyPolicyOpen}
          onClose={() => setPrivacyPolicyOpen(false)}
        />

        <TermsAndConditionsModal
          open={termsOpen}
          onClose={() => setTermsOpen(false)}
        />
      </>
    );
  }
}

const webStyle = {
  inputWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: "360px",
    mb: 1
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "2px",
    color: "#334155"
  },
  textFieldStyle: {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderWidth: "1px",
      },
    },
    backgroundColor: "#ffffff",
    color: "#94A3B8",
    mb: "3px",
  },
  inputStyle: {
    border: "1px solid #CBD5E1",
    borderRadius: '20px',
    fontWeight: 400,
    width: "100%"
  },
  buttonStyle: {
    height: "50px",
    width: "100%",
    maxWidth: "360px",
    marginTop: "25px",
    marginBottom: "20px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none"
  },
  passwordRequirements: {
    marginTop: '10px',
  },
  requirementItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  checkIcon: {
    color: '#34D399',
    fontSize: '16px',
    marginRight: '8px',
  },
  uncheckIcon: {
    color: '#9E9E9E',
    fontSize: '16px',
    marginRight: '8px',
  },
  requirementText: {
    fontSize: '14px',
    color: '#333',
  },
  consentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#F8FAFC',
    borderRadius: '4px',
  },
  checkbox: {
    color: '#3B82F6',
    '&.Mui-checked': {
      color: '#3B82F6',
    },
    padding: '0',
    marginRight: '8px',
  },
  consentText: {
    fontSize: '14px',
    color: '#1E293B',
    display: 'inline',
    lineHeight: 1.5,
  },
  consentButton: {
    color: '#0F172A',
    textTransform: 'none',
    padding: '0 2px',
    minWidth: 'auto',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
};


// Customizable Area End