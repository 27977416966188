import React from "react";
import { Box, Divider, Typography, styled } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
interface NavigationButtonProps {
  label: string;
  onClick: () => void;
  icon: string;
}
export const NavigationButton: React.FC<NavigationButtonProps> = ({
  label,
  onClick,
  icon,
}) => {
  return (
    <StyledNavButton onClick={onClick}>
      <Box sx={webStyle.navButtons}>
        <Box sx={webStyle.navIconLabel}>
          <img src={icon} height={"24px"} width={"24px"} />
          <Typography sx={webStyle.navLabel}>{label}</Typography>
        </Box>
        <NavigateNextIcon sx={{ color: "#0074BF" }} />
      </Box>
      <Divider className="divider" />
    </StyledNavButton>
  );
};


const StyledNavButton = styled(Box)({
  "& .divider": {
    display: "none",
  },
  "@media (max-width: 900px)": {
    "& .divider": {
      display: "block",
    },
  },
});

const webStyle = {
  navButtons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 10px 12px 20px",
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: "20px",
  },
  navIconLabel: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  navLabel: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 500,
    color: "#0F172A",
  },
};
