import React from "react";

import {
  // Customizable Area Start
  Box,
  Typography,
  InputLabel,
  Button,
  TextField,
  Grid,
  IconButton,
  // Customizable Area End
} from "@mui/material";


import { loginLogo, loginImage } from "./assets";

import { Link } from 'react-router-dom';

// Customizable Area Start
import * as Yup from "yup";
import { Formik } from "formik";
import LoginController, { Props } from "./LoginController.web";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// Customizable Area End

export default class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div className="Toastify"></div>
        <Grid container direction={'row'}
          sx={{ minHeight: '100%' }}
        >
          <Grid item xs={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '20px'
            }}
          >
            <Box >
              <img src={loginLogo} style={{ padding: "15px", width: "150px", height: "61px" }} />
            </Box>
            <Formik
              initialValues={{
                password: "",
                type: "Technician",
                email: "",
              }}
              data-test-id="formik"
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Invalid email format")
                  .required("Email is required"),
                type: Yup.string().required("Type is required"),
                password: Yup.string()
                  .nullable()
                  .required("Password is required"),
              })}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={() => {
                this.handleLogin();
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <Box sx={webStyle.formWrapper}>
                    <Box sx={webStyle.inputWrapper}>
                      <Typography variant="h6" sx={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 700, fontSize: "22px", marginBottom: '15px', color: "#001C55", lineHeight: '24px'
                      }}>
                        Welcome back!
                      </Typography>
                    </Box>
                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Email</InputLabel>
                      <TextField
                        data-test-id={"email"}
                        name="email"
                        id="email"
                        value={props.values.email}
                        onChange={(event) => {
                          props.setFieldValue("email", event.target.value);
                          this.onValueChange("email", event.target.value);
                        }}
                        size="medium"
                        fullWidth={true}
                        variant="outlined"
                        type="text"
                        onBlur={props.handleBlur}
                        placeholder="Enter your email"
                        sx={{
                          "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #CBD5E1",
                              borderWidth: "1px",
                            },
                          },
                          backgroundColor: "#ffffff",
                          color: "#94A3B8",
                          mb: "3px",
                        }}

                        InputProps={{
                          style: {
                            border: "1px solid #CBD5E1",
                            borderRadius: '20px',
                            fontWeight: 400,
                            width: "360px"

                          }
                        }}
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "email"
                      )}
                    </Box>
                    <Box sx={webStyle.inputWrapper}>
                      <InputLabel style={webStyle.label}>Password</InputLabel>
                      <TextField
                        id="password"
                        data-test-id={"password"}
                        value={props.values.password}
                        onChange={(event) => {
                          props.setFieldValue("password", event.target.value);
                          this.onValueChange("password", event.target.value);
                        }}
                        size="medium"
                        name="password"
                        onBlur={props.handleBlur}
                        variant="outlined"
                        placeholder="Enter your password"
                        fullWidth={true}
                        type={this.state.showPassword ? "text" : "password"}
                        sx={{
                          "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "1px solid #CBD5E1",
                              borderWidth: "1px",
                            },
                          },
                          backgroundColor: "#ffffff",
                          color: "#94A3B8",
                          mb: "3px",
                        }}

                        InputProps={{
                          style: {
                            border: "1px solid #CBD5E1",
                            width: "360px",
                            borderRadius: '20px',
                            fontWeight: 400
                          },
                          endAdornment: (
                            <IconButton
                              onClick={() => this.togglePasswordVisibility('showPassword')}
                              edge="end"
                            >
                              {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          ),
                        }}
                      />
                      {this.getErrorMessage(
                        props.touched,
                        props.errors,
                        "password"
                      )}

                    </Box>
                    <Box display={"flex"} justifyContent={"end"} sx={{ width: '100%', maxWidth: '360px' }}>

                      <Typography variant="h6" ><Link to={'/forgot-password'} style={{
                        fontFamily: 'Montserrat, sans-serif', display: "inline",
                        fontWeight: 700, fontSize: "14px", color: "#0074BF", textDecoration: "none",
                        marginLeft: "7px"
                      }}>
                        Forgot password?
                      </Link>
                      </Typography>
                    </Box>

                    <Button
                      type="submit"
                      sx={webStyle.buttonStyle}
                      data-test-id="LoginBtn"
                      variant="contained"
                    >
                      Login
                    </Button>

                    <Typography variant="h6" align="center" sx={{
                      fontFamily: 'Montserrat, sans-serif',
                      fontWeight: 400, fontSize: "16px", color: "#0F172A",
                      display: "inline"
                    }}>
                      Don’t have an account?
                      <Link to="/Signup" style={{
                        fontFamily: 'Montserrat, sans-serif', display: "inline",
                        fontWeight: 700, fontSize: "16px", color: "#0074BF", textDecoration: "none",
                        marginLeft: "7px"
                      }}>
                        Sign up
                      </Link >
                    </Typography>

                  </Box>
                </form>
              )}
            </Formik>
            <Box></Box>
          </Grid>
          <Grid item xs={6} padding={0}>
            <img src={loginImage} style={{ height: "100vh", width: "100%" }} />
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

const webStyle = {
  inputWrapper: {
    position: "relative",
    width: "100%",
    maxWidth: "360px",
    mb: 1
  },
  mainWrapper: {
    paddingBottom: "30px",
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    justifyContent: "center",
    height: "100vh",
  },
  buttonStyle: {
    height: "50px",
    width: "360px",
    marginTop: "25px",
    marginBottom: "50px",
    border: "none",
    borderRadius: "20px",
    backgroundColor: "#0074BF",
    color: "white",
    cursor: "pointer",
    fontWeight: 700,
    textTransform: "none"
  },
  label: {
    fontSize: "16px",
    fontWeight: 600,
    marginTop: "3px",
    color: "#334155"
  },
  formWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
};